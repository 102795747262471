import { Box } from '@mui/material';
import { EmployeeInformation } from '@/types/user.interface';
import { EmployeeCardContent } from './EmployeeCardContent';

import React from 'react';
import { EmployeeCardHeader } from '../DashboardRH/EmployeeCard';
import { ListCardActions, ListCardContent, ListCardContentLine, ListCardItem } from '../DashboardRH/ListCard';
import { FormatDate } from '@/constants/date.constants';
import moment from 'moment';

const emptyLabel = '-';

const renderField = (
  employeeInformation: EmployeeInformation | undefined,
  getter: (employee: EmployeeInformation) => string | undefined,
  label: string,
) => (
  <ListCardContentLine separator={':'} label={label}>
    {employeeInformation == null ? emptyLabel : getter(employeeInformation) ?? emptyLabel}
  </ListCardContentLine>
);

interface EmployeeCardProps {
  employeeInformation?: EmployeeInformation;
}

export const EmployeeCard = ({ employeeInformation }: EmployeeCardProps) => {
  return (
    <ListCardItem>
      <EmployeeCardHeader employeeInformation={employeeInformation as any} />

      <ListCardContent>
        <EmployeeCardContent employeeInformation={employeeInformation} />
      </ListCardContent>

      <ListCardActions collapse collapseTitle="Informations complémentaires">
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            {renderField(
              employeeInformation,
              (_) => moment(_.birthday).format(FormatDate.FRENCH_DATE),
              'Date de naissance',
            )}
            {renderField(employeeInformation, (_) => _.zipcode, 'Code Postal')}
          </Box>
          <Box sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
            {renderField(employeeInformation, (_) => _.birth_commun, 'Département naissance')}
            {renderField(
              employeeInformation,
              (_) => (_.employee_login?.status === 'active' ? 'Oui' : 'Non'),
              'Inscription confirmée',
            )}
          </Box>
        </Box>
      </ListCardActions>
    </ListCardItem>
  );
};
