import { NavigationContext } from '../../../components/Context/NavigationContext';
import { DefaultLayout } from '../../../components/layouts';

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { RouterConfig } from '../../../config/RouterConfig';
import { useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';
import { DeclarationTitle } from '@/features/declaration/components/DeclarationTitle';

export const DeclarationProlongationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const document = location.state?.document || {};
  const absenceType = document?.absenceType?.value;
  const [stateAbsence, setStateAbsence] = useState<string>(document.stateAbsence ? document.stateAbsence.value : '1');
  const { setCurrentStep } = useContext(NavigationContext);

  const user = useAppSelector((state) => state.accountContext);

  const handleClickNext = () => {
    if (user) {
      navigate(RouterConfig.declarationType2Page().path, {
        state: {
          document: {
            ...document,
            stateAbsence: { value: stateAbsence },
          },
        },
      });
    } else {
      navigate('/login', { state: { ...location.state } });
    }
  };
  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_PROLONGATION);
  }, []);
  return (
    <DefaultLayout>
      <div className="container-fluid">
        <StepProgressBar type={absenceType} />

        <DeclarationTitle title="C'est un(e)" />

        <form className="form-legacy" onSubmit={handleClickNext} style={{ padding: 0 }}>
          <label>
            <select
              className="form-select"
              name="absence"
              value={stateAbsence}
              onChange={(e) => setStateAbsence(e.target.value)}
              required
            >
              <option value={1}>Arrêt Initial</option>
              <option value={2}>Prolongation</option>
            </select>
          </label>

          <Spacer size="medium" />
          <ActionButton startIcon={null} type="submit" variant="contained" actionName="next" />
          <Spacer />
          <Button color="secondary" onClick={() => navigate(RouterConfig.declarationPage().path)}>
            Précédent
          </Button>
        </form>
      </div>
    </DefaultLayout>
  );
};
