import React, { useState } from 'react';
import { Box, CardActions, Divider, styled, Typography } from '@mui/material';
import { Button } from '@/components/Button';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';

const Actions = styled(CardActions)(({ theme }) => ({
  display: 'inherit',
  margin: 0,
  padding: 0,
  fontSize: 12,
}));

interface ListCardActionsProps {
  children?: React.ReactNode;
  collapse?: boolean;
  collapseTitle?: string;
}

export const ListCardActions = (props: ListCardActionsProps) => {
  const { children, collapse, collapseTitle } = props;
  const [isShowMore, setIsShowMore] = useState(false);

  return (
    <Actions>
      <Box>
        <Divider sx={{ width: '100%' }} />
        <Box sx={{ width: '100%' }}>
          {collapse ? (
            <>
              <Box textAlign="center">
                <StyledButton
                  variant={isShowMore ? 'contained' : 'text'}
                  onClick={() => setIsShowMore(!isShowMore)}
                  fullWidth
                  disableElevation
                  sx={
                    isShowMore
                      ? (theme) => ({
                          backgroundColor: theme.colorScheme.surface,
                          color: theme.colorScheme.onSurfaceVariant,
                        })
                      : {}
                  }
                >
                  <Box display={'flex'} justifyContent={'center'}>
                    {collapseTitle && (
                      <Typography
                        mr={0.5}
                        fontWeight={600}
                        lineHeight={2}
                        sx={(theme) => ({ color: theme.colorScheme.onSurfaceVariant })}
                      >
                        {collapseTitle}
                      </Typography>
                    )}
                    {isShowMore ? (
                      <ArrowDropUpRounded
                        sx={(theme) => ({ fontSize: 24, color: theme.colorScheme.onSurfaceVariant })}
                      />
                    ) : (
                      <ArrowDropDownRounded
                        sx={(theme) => ({ fontSize: 24, color: theme.colorScheme.onSurfaceVariant })}
                      />
                    )}
                  </Box>
                </StyledButton>
              </Box>

              {isShowMore && <Box p={2.5}>{children}</Box>}
            </>
          ) : (
            <Box p={1.25}>{children}</Box>
          )}
        </Box>
      </Box>
    </Actions>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(4),
  borderRadius: 0,
  ':hover': { backgroundColor: theme.colorScheme.primaryContainer },
}));
