import { z } from 'zod';
import { ParcoursStateData } from './ParcoursStateData';
import { ParcoursStateStatus } from './ParcoursStateStatus';
import { ParcoursStateOrigin } from './ParcoursStateOrigin';
import { ParcoursActionName } from './ParcoursAction';
import { toZodEnumValues } from './toZodEnumValues';

export const ParcoursState = Object.assign(
  z.object({
    id: z.string().uuid(),
    parcours_id: z.string().uuid(),
    employee_information_id: z.string().uuid(),
    status: z.enum(toZodEnumValues(ParcoursStateStatus)),
    trigger_origin: z.enum(toZodEnumValues(ParcoursStateOrigin)),
    /**
     * IMPORTANT : This property should be never updated after creation !
     */
    trigger_parcours_data: z.object(
      {
        script: z.object({}),
        action_run: z.record(
          ParcoursActionName,
          z.string({ description: 'Date when the action has to be run (null for now)' }).date().nullable(),
        ),
      },
      {
        description: 'A snapshot of the parcours that was triggered',
      },
    ),
    state: ParcoursStateData,

    created_at: z.string(),
    updated_at: z.string(),
    parcours: z.object({ name: z.string().optional() }),
    employee_information: z.object({ firstname: z.string().optional(), lastname: z.string().optional() }),
  }),
  {
    /**
     * Return true when parcoursState can be run
     *
     * @param parcoursState
     * @param actionName
     * @param timeNow
     */
    shouldActionRun(parcoursState: ParcoursState, actionName: ParcoursActionName, timeNow: number): boolean {
      // eslint-disable-next-line security/detect-object-injection
      const runAt = parcoursState.trigger_parcours_data.action_run[actionName];
      if (runAt != null) {
        return timeNow >= Date.parse(runAt);
      }

      return true;
    },
  },
);

export interface ParcoursState extends z.infer<typeof ParcoursState> {}
export interface ParcoursStateCreation extends Omit<ParcoursState, 'id' | 'created_at' | 'updated_at'> {}
