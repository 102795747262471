import * as React from 'react';
import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  type InputLabelProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  type SelectProps,
  Tooltip,
} from '@mui/material';
import { AppSelectItem } from './data';
import { AppInputLabel } from '../AppInputLabel';
import { AppInput } from '../AppInput';

export type AppSelectProps<InputValue = string | undefined> = Omit<SelectProps<InputValue>, 'onChange'> & {
  label?: React.ReactNode;
  items: Array<AppSelectItem<InputValue>>;
  helperText?: string;
  error?: boolean;
  LabelProps?: InputLabelProps;
  FormControlProps?: FormControlProps;
  noHelper?: boolean;
  onChange?: (event: SelectChangeEvent<InputValue>, value: InputValue) => void;
  tooltip?: React.ReactNode;
};

// @ts-ignore
export const AppSelect: <InputValue>(props: AppSelectProps<InputValue>) => JSX.Element = React.forwardRef(
  function AppSelect<InputValue>(
    props: AppSelectProps<InputValue>,
    ref: React.ForwardedRef<typeof Select<InputValue>>,
  ) {
    const TextFieldProps: Pick<AppSelectProps, 'disabled' | 'readOnly' | 'required' | 'error'> = {
      disabled: props.disabled,
      error: props.error,
      readOnly: props.readOnly,
      required: props.required,
    };

    const {
      label,
      items,
      helperText: helper,
      placeholder,
      LabelProps,
      FormControlProps,
      noHelper,
      onChange,
      ...otherProps
    } = props;

    const handleChange: SelectProps<InputValue>['onChange'] = (event) => {
      const { target } = event;
      const newValue = target.value;
      onChange?.(event, newValue as InputValue);
    };

    const AppSelectItem = (item: AppSelectItem<InputValue>) => {
      const label = (
        <Box key={`MenuItemLabel-${item.value}`} width="100%">
          {item.label}
        </Box>
      );

      if (item.tooltip == null) return label;
      return (
        <Tooltip title={item.tooltip} placement="right" arrow>
          {label}
        </Tooltip>
      );
    };

    return (
      <FormControl size="small" sx={{ width: '100%' }} {...TextFieldProps} {...FormControlProps}>
        <AppInputLabel shrink {...LabelProps} style={{ top: '7px', left: '-14px' }}>
          {label}
        </AppInputLabel>
        <Select ref={ref} {...otherProps} onChange={handleChange} input={<AppInput />}>
          {placeholder && (
            <MenuItem disabled value={undefined}>
              {placeholder}
            </MenuItem>
          )}
          {items.map((item) => (
            <MenuItem
              key={`MenuItem-${item.value}`}
              value={
                // Ici le typage de MenuItem de la lib n'est pas correct
                item.value as any
              }
            >
              <AppSelectItem {...item} />
            </MenuItem>
          ))}
        </Select>
        {noHelper !== true && <FormHelperText>{helper ?? ' '}</FormHelperText>}
      </FormControl>
    );
  },
);
