import React from 'react';
import { Service, ServiceCategoryType } from '@/types';
import { Box, BoxProps, styled, Typography } from '@mui/material';

export interface ServiceCardInfosProps extends Pick<BoxProps, 'sx'> {
  value: Service;
}

export function ServiceCardInfos(props: ServiceCardInfosProps) {
  const { value, ...boxProps } = props;

  const categories = value.categories?.filter((category) => category.type === ServiceCategoryType.Category) ?? [];
  const subcategories = value.categories?.filter((category) => category.type === ServiceCategoryType.Subcategory) ?? [];

  return (
    <Box {...boxProps}>
      <Box sx={(theme) => ({ mt: theme.spacing(0.5) })}>
        <Typography textTransform={'uppercase'} fontWeight={600} fontSize={16} lineHeight={'normal'}>
          {value.title}
        </Typography>
      </Box>

      <Box sx={(theme) => ({ mt: theme.spacing(0.5) })}>
        <CategoryText textTransform={'uppercase'} fontWeight={600} fontSize={10}>
          {categories.map(({ reference }) => reference.label).join(' / ')}
        </CategoryText>
      </Box>

      <Box>
        <CategoryText fontWeight={400} fontSize={12}>
          {subcategories.map(({ reference }) => reference.label).join(' / ')}
        </CategoryText>
      </Box>
    </Box>
  );
}

const CategoryText = styled(Typography)(({ theme }) => ({
  lineHeight: 'normal',
  color: theme.colorScheme.onSurfaceVariant,
}));
