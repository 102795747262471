import { Absence, DocumentStatus } from '../../../types/absences.type';
import React, { useState } from 'react';
import ViewPdfModal from '../../base/ViewPdfModal';
import { IconButton, IconButtonProps } from '@/components/IconButton';
import { File } from '@/components/AppIcon';

interface DocumentIconProperties {
  absence: Absence;
  size?: IconButtonProps['size'];
}

const DocumentIcon = ({ absence, size }: DocumentIconProperties) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const hasStatus = (status: DocumentStatus): boolean => {
    const documents = absence?.documents;
    return documents?.some((document_) => document_.status === status) ?? false;
  };

  const shouldViewDocument = () => {
    const documents = absence?.documents?.filter((doc) => doc.status !== DocumentStatus.ANNEXED);

    if (documents?.length === 0) return false;
    return (
      hasStatus(DocumentStatus.APPROVED) || hasStatus(DocumentStatus.PENDING) || hasStatus(DocumentStatus.REJECTED)
    );
  };

  const getBadgeColor = () => {
    const documents = absence?.documents;
    if (documents?.length === 0) return 'error';
    return hasStatus(DocumentStatus.APPROVED)
      ? 'success'
      : hasStatus(DocumentStatus.PENDING)
      ? 'warning'
      : hasStatus(DocumentStatus.REJECTED)
      ? 'error'
      : 'default';
  };
  const getTooltip = () => {
    return hasStatus(DocumentStatus.APPROVED)
      ? 'Le justificatif est conforme'
      : hasStatus(DocumentStatus.PENDING)
      ? 'Le justificatif déposé doit être validé'
      : hasStatus(DocumentStatus.REJECTED)
      ? "Aucun justificatif n'est conforme"
      : 'En attente de justificatif';
  };

  const getDocumentPath = () => {
    const documents = absence?.documents;
    const documentApprouved = documents?.find((document) => document.status === DocumentStatus.APPROVED);
    if (documentApprouved) return documentApprouved.url;
    const documentPending = documents?.find((document) => document.status === DocumentStatus.PENDING);
    if (documentPending) return documentPending.url;
    const documentRejected = documents?.find((document) => document.status === DocumentStatus.REJECTED);
    if (documentRejected) return documentRejected.url;
    return null;
  };

  return (
    <>
      <IconButton
        size={size}
        onClick={() => {
          setModalIsOpen(true);
        }}
        badge={true}
        BadgeProps={{
          color: getBadgeColor(),
        }}
        TooltipProps={{
          title: getTooltip(),
        }}
        icon={<File sx={{ color: 'white' }} />}
        sx={{ mr: 1 }}
      />
      {shouldViewDocument() && absence?.employee_information && (
        <ViewPdfModal
          visibleModal={modalIsOpen}
          setVisibleModal={setModalIsOpen}
          title={'Justificatif'}
          path={getDocumentPath()}
        />
      )}
    </>
  );
};

export default DocumentIcon;
