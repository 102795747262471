import * as React from 'react';
import moment from 'moment';
import classNames from 'clsx';
import { Grid, ListItem, Typography, type ListItemProps } from '@mui/material';
import { ParcoursState } from '@/types';

const componentName = 'ParcoursStateListItem';

export interface ParcoursStateListItemProps extends ListItemProps {
  state: ParcoursState;
}

export function ParcoursStateListItem(props: ParcoursStateListItemProps) {
  const { state, ...otherProps } = props;

  const employee = `${state.employee_information.firstname} ${state.employee_information.lastname}`.trim();
  const date = moment(state.updated_at).format('DD/MM/YYYY[ à ]HH:mm');
  const parcours = state.parcours.name ?? 'Nom du parcours inconnu';

  return (
    <ListItem className={classNames(componentName, componentName)} disablePadding {...otherProps}>
      <Grid container>
        <Grid item xs={2.5}>
          <ItemText fontWeight={600}>{employee}</ItemText>
        </Grid>
        <Grid item xs={2.5}>
          <ItemText>{date}</ItemText>
        </Grid>
        <Grid item xs={7}>
          <ItemText>{parcours}</ItemText>
        </Grid>
      </Grid>
    </ListItem>
  );
}

const ItemText = (props: { fontWeight?: 400 | 600; children?: string }) => (
  <Typography p={1.5} pt={2} pb={2} lineHeight={'normal'} fontSize={14} fontWeight={props.fontWeight ?? 400}>
    {props.children}
  </Typography>
);
