import React, { useEffect, useRef, useState, useCallback } from 'react';
import { PermissionBox } from '@/components/PermissionBox';
import { ParcoursStateQuery } from '@/query';
import { RHPage } from '@/components/RHPage';
import { ParcoursStateList } from '@/components/ParcoursStateList';
import { ParcoursState } from '@/types';
import { Box } from '@mui/material';

export const ParcoursStatePage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<Array<ParcoursState>>([]);
  const [hasMore, setHasMore] = useState(true);

  const queryState = ParcoursStateQuery.useIndex({ page, per_page: 20 });

  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  const loadMore = useCallback(
    () => (hasMore && queryState.isFetched ? setPage((prevPage) => prevPage + 1) : undefined),
    [hasMore, queryState.isFetched],
  );

  useEffect(() => {
    if (queryState.data) {
      setItems((prevItems) => [...prevItems, ...queryState.data]);
      if (queryState.data.length === 0) setHasMore(false);
    }
  }, [queryState.data]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(
      (entries) => (entries[0].isIntersecting && hasMore ? loadMore() : undefined),
      { threshold: 1 },
    );
    if (lastElementRef.current) observerRef.current.observe(lastElementRef.current);
    return () => (observerRef.current ? observerRef.current.disconnect() : undefined);
  }, [loadMore, hasMore]);

  return (
    <PermissionBox scope="management" action="access">
      <RHPage title={'Historique des envois'}>
        <ParcoursStateList items={items} />
        <Box ref={lastElementRef} />
      </RHPage>
    </PermissionBox>
  );
};
