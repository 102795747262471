import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  EmployeeContact,
  EmployeeContactType,
  EmployeeContactTypeModule,
  EmployeeInformation,
} from '@/types/user.interface';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { ListCardContentLine } from '../DashboardRH/ListCard';

const emptyLabel = '-';
const renderField = (
  employeeInformation: EmployeeInformation | undefined,
  getter: (employee: EmployeeInformation) => string | undefined,
  label: string,
) => (
  <ListCardContentLine separator={':'} label={label}>
    {employeeInformation == null ? emptyLabel : getter(employeeInformation) ?? emptyLabel}
  </ListCardContentLine>
);
const renderContact = (employeContactList: Array<EmployeeContact> | undefined, contactType: EmployeeContactType) => {
  const contact = employeContactList?.find((contact: EmployeeContact) => contact?.type === contactType);
  return (
    <ListCardContentLine separator={':'} label={EmployeeContactTypeModule.format(contactType)}>
      {contact?.lastname == null
        ? contact?.email == null
          ? emptyLabel
          : contact?.email
        : [contact?.firstname, contact?.lastname].join(' ')}
    </ListCardContentLine>
  );
};

interface EmployeeCardContentProps {
  employeeInformation?: EmployeeInformation;
  showMore?: boolean;
}

export const EmployeeCardContent = ({ employeeInformation, showMore }: EmployeeCardContentProps) => {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          {renderField(employeeInformation, (_) => _.business?.name, 'Société')}
          {renderField(employeeInformation, (_) => _.category, 'Status')}
          {renderField(employeeInformation, (_) => _.job_title, 'Fonction')}
          {renderField(employeeInformation, (_) => _.organisation?.short_description, 'Service')}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minWidth: '30rem',
          }}
        >
          {renderField(employeeInformation, (_) => _.description, 'Matricule')}
          {renderField(employeeInformation, (_) => _.time_package, 'Forfait temps')}
          {renderContact(employeeInformation?.employee_contacts, EmployeeContactType.Manager1)}
          {renderContact(employeeInformation?.employee_contacts, EmployeeContactType.GTA1)}
        </Box>
      </Box>

      {showMore && (
        <>
          <Typography fontSize={24} marginTop={2} marginBottom={1}>
            {' '}
            Informations complémentaires{' '}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              {renderField(
                employeeInformation,
                (_) => moment(_.birthday).format(FormatDate.FRENCH_DATE),
                'Date de naissance',
              )}
              {renderField(employeeInformation, (_) => _.zipcode, 'Code Postal')}
            </Box>
            <Box sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
              {renderField(employeeInformation, (_) => _.birth_commun, 'Département naissance')}
              {renderField(
                employeeInformation,
                (_) => (_.employee_login?.status === 'active' ? 'Oui' : 'Non'),
                'Inscription confirmée',
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
