import React from 'react';
import './AccueilRH.css';
import { useNavigate } from 'react-router-dom';
import imgStats from '../../../assets/img-stats-dashboard.svg';
import { PieChartsEncours } from '../../Charts/PieChartsEncours';
import { PRO_URL } from '../../../constants/urls.constants';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { IfPermission } from '@/components/IfPermission';
import { ListCardActions } from '../ListCard';
import { ActionButton } from '@/components/ActionButton';

const WelcomeStats = () => {
  const navigate = useNavigate();

  const BtnAllStats = () => {
    return (
      <ActionButton
        color="secondary"
        actionName="next"
        label={'Toutes les statistiques'}
        onClick={() => {
          navigate(PRO_URL.PREFIXE_PRO + PRO_URL.STATS_EN_COURS);
        }}
      />
    );
  };

  const CardStatistique = () => {
    return (
      <Card elevation={3} sx={(theme) => ({ border: '1px solid', borderColor: theme.shape.borderColorVariant })}>
        <CardHeader
          avatar={<img src={imgStats} />}
          sx={(theme) => ({
            backgroundColor: theme.colorScheme.surface,
            color: theme.colorScheme.onPrimary,
            paddingTop: theme.spacing(1.75),
            paddingBottom: theme.spacing(1.75),
          })}
          title={
            <Box display={'flex'}>
              <Typography flexGrow={1} fontSize={14} fontWeight={600}>
                Statistiques
              </Typography>
            </Box>
          }
        />

        <CardContent sx={{ p: 0 }}>
          <PieChartsEncours forPage="Dashboard" />
        </CardContent>

        <ListCardActions>{<BtnAllStats />}</ListCardActions>
      </Card>
    );
  };

  return (
    <div className="col-12">
      <IfPermission scope="statistic" action="access">
        <CardStatistique />
      </IfPermission>
    </div>
  );
};

export default WelcomeStats;
