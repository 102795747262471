/* eslint-disable unicorn/prefer-module */
import React from 'react';
import EntretiensLegalCard from './components/EntretiensLegalCard';
import { PermissionBox } from '@/components/PermissionBox';
import { RHPage } from '@/components/RHPage';
import { ListCard } from '@/components/DashboardRH/ListCard';

const dataEntretiensLegal = [
  {
    title: 'Guides pratiques',
    items: [
      {
        title: `Guide RH de l'entretien de retour à l'emploi`,
        path: require("../../assets/pdf/Guide d'entretien de retour à l'emploi - Guide d'accompagnement RH.pdf"),
      },
      {
        title: `Guide RH de l'entretien de liaison`,
        path: require("../../assets/pdf/Guide d'entretien de liaison - Guide d'accompagnement RH.pdf"),
      },
      {
        title: `Guide Manager de l'entretien de retour à l'emploi`,
        path: require("../../assets/pdf/Guide d'entretien de retour à l'emploi - Guide d'accompagnement manager n+1.pdf"),
      },
      {
        title: `Guide Manager de l'entretien de liaison`,
        path: require("../../assets/pdf/Guide d'entretien de liaison - Guide d'accompagnement manager n+1.pdf"),
      },
    ],
  },
  {
    title: `Supports d'entretiens`,
    items: [
      {
        title: `Support d'entretien de retour à l'emploi pour les RH`,
        path: require("../../assets/pdf/Guide d'entretien de retour à l'emploi - Guide avec RH .pdf"),
      },
      {
        title: `Support d'entretien de liaison pour les RH`,
        path: require("../../assets/pdf/Guide d'entretien de liaison - Guide avec RH.pdf"),
      },
      {
        title: `Support d'entretien de retour à l'emploi pour les managers`,
        path: require("../../assets/pdf/Guide d'entretien de retour à l'emploi - Guide avec manager n+1.pdf"),
      },
      {
        title: `Support d'entretien de liaison pour les managers`,
        path: require("../../assets/pdf/Guide d'entretien de liaison - Guide avec manager n+1.pdf"),
      },
    ],
  },
];

const EntretiensLegal = () => {
  return (
    <PermissionBox scope="management" action="access">
      <RHPage title="Centre de ressources">
        {dataEntretiensLegal.map((item, index) => (
          <ListCard key={index}>
            <EntretiensLegalCard dataItems={item?.items} title={item.title} />
          </ListCard>
        ))}
      </RHPage>
    </PermissionBox>
  );
};

export default EntretiensLegal;
