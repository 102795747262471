import React from 'react';
import { Service, ServiceFinanceLabel } from '@/types';
import { Chip, styled } from '@mui/material';
import { IconButton } from '../IconButton';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { IconButtonProps } from '../IconButton/IconButton';

export interface ServiceCardActionsProps extends Omit<IconButtonProps, 'action' | 'value' | 'icon'> {
  value: Service;
  action?: IconButtonProps;
}

export function ServiceCardActions(props: ServiceCardActionsProps) {
  const { value, action, ...buttonProps } = props;

  return (
    <>
      {value.funding && <FoundingChip label={ServiceFinanceLabel[`${value.funding}`]} size="small" />}

      {action && (
        <ActionButton
          size="small"
          className="ActionButton"
          style={{ borderRadius: '0 0 0.25rem 0', left: '0' }}
          {...action}
          {...buttonProps}
        />
      )}

      <ActionButton
        icon={faHeart}
        size="small"
        className="ActionButton"
        style={{ borderRadius: '0 0 0 0.25rem', right: '0' }}
        {...buttonProps}
      />
    </>
  );
}

const ActionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.colorScheme.tertiary,
  color: theme.colorScheme.onTertiary,
  height: theme.spacing(4),
  width: theme.spacing(4),
  position: 'absolute',
  top: '0',
  ':hover': {
    backgroundColor: theme.colorScheme.onTertiary,
    color: theme.colorScheme.tertiary,
  },
}));

const FoundingChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.colorScheme.tertiary,
  color: theme.colorScheme.onTertiary,
  position: 'absolute',
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  height: theme.spacing(2),
  fontSize: theme.spacing(1),
}));
