import React, { useEffect } from 'react';
import { DefaultLayout } from '../../../components/layouts';

import { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useError } from '@/shared/useError';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { colors } from '../../../constants/theme.constants';
import { AccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';
import { DeclarationTitle } from '@/features/declaration/components/DeclarationTitle';

export const DeclarationMessagePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector(AccountContext.getUser);

  const document = location.state.document ?? {};
  const absenceType = document.absenceType?.value;
  const [isTextArea, setIsTextArea] = useState<boolean>(document.textArea || false);
  const [message, setMessage] = useState<string>(document.message || '');
  const { setCurrentStep } = useContext(NavigationContext);
  const { error, triggerError, clearError } = useError();

  const handleClickNext = async () => {
    if (user) {
      navigate('/declaration-validation', {
        state: {
          ...location.state,
          document: {
            ...document,
            message: message,
            textArea: isTextArea,
          },
        },
      });
    } else {
      navigate('/login', { state: location.state });
    }
  };

  const handleClickPrev = () => {
    navigate('/declaration-questions-contact', {
      state: location.state,
    });
  };

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_QUESTIONS_MESSAGE);
  }, []);

  return (
    <DefaultLayout>
      <div className="container-fluid">
        <StepProgressBar type={absenceType} />
        <DeclarationTitle title="Je souhaite adresser un message personnalisé à mon manager" />

        <div style={{ maxWidth: '350px', width: '100%' }}>
          <FormControl sx={{ transform: 'translateX(50%)' }}>
            <RadioGroup row value={isTextArea} onChange={(event) => setIsTextArea(event.target.value === 'true')}>
              <FormControlLabel value={'true'} control={<Radio />} label="Oui" sx={{ marginRight: '3rem' }} />
              <FormControlLabel value={'false'} control={<Radio />} label="Non" />
            </RadioGroup>
          </FormControl>
          <Spacer size="large" />
        </div>

        {isTextArea ? (
          <div className="col-xl-6 col-12">
            <p
              style={{
                fontStyle: 'italic',
                fontWeight: '900',
                // color: 'red',
              }}
            >
              Dans la rédaction du présent message, vous pouvez renseigner des informations librement.
              <span
                style={{
                  marginLeft: '5px',
                }}
              >
                <Tooltip title="Nous vous invitons à ne mentionner aucune information relative à votre éventuelle pathologie et situation médicale. Conformément à la réglementation, vous devez par ailleurs veiller impérativement à ne pas faire de commentaires excessifs ou insultants envers les individus.">
                  <InfoIcon
                    style={{
                      color: colors.primary,
                    }}
                  />
                </Tooltip>
              </span>
            </p>

            <textarea
              className="message-container"
              placeholder="Votre message"
              defaultValue={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        ) : (
          <></>
        )}

        <div style={{ maxWidth: '350px', width: '100%' }}>
          <ActionButton
            startIcon={null}
            type="submit"
            variant="contained"
            actionName="next"
            disabled={error != null}
            onClick={handleClickNext}
            style={{ width: '100%' }}
          />
          <Spacer />
          <Button color="secondary" onClick={handleClickPrev} style={{ width: '100%' }}>
            Précédent
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
};
